import pagesAndPermissions from '@/main/pages/pagesAndPermissions'
import primeOpinionPagesAndPermissions from '@/main/pages/primeOpinionPages'
import fiveSurveysPagesAndPermissions from '@/main/pages/fiveSurveysPages'
import primeSamplePagesAndPermissions from '@/main/pages/primeSamplePages'
import heyCashPagesAndPermissions from '@/main/pages/heyCashPages'

function getPageAndPermission (pagesAndPermissions) {
  // implemented stack instead of recursive function to avoid stack overflow
  const returnData = []
  const stack = [...pagesAndPermissions]

  while (stack.length) {
    const item = stack.pop()

    if (item.children) {
      stack.push(...item.children)
    } else {
      returnData.push(item)
    }
  }
  return returnData
}

export default getPageAndPermission([
  ...pagesAndPermissions,
  ...primeOpinionPagesAndPermissions,
  ...fiveSurveysPagesAndPermissions,
  ...primeSamplePagesAndPermissions,
  ...heyCashPagesAndPermissions
])
